














































































import { Table, TableColumn } from 'element-ui';
import { Component, Vue } from 'vue-property-decorator';
import { Inject } from 'inversify-props';
import moment from 'moment';
import Day from '@/modules/common/types/day.type';
import MIPriceFilter from '@/modules/common/filters/mi-price.filter';
import UserService, { UserServiceS } from '@/modules/user/user.service';
import DocumentFiltersService, { DocumentFiltersServiceS } from '@/modules/document-filters/document-filters.service';
import FleetService, { FleetServiceS } from '@/modules/cars/modules/fleet/fleet.service';
import Occupancy from '@/modules/occupancy/components/occupancy.vue';
import CalendarEventsContainer from '@/modules/events/components/calendar-events-container.vue';
import LoaderWrapper from '@/modules/common/components/loader-wrapper.vue';
import CarsDotDropdown from '@/modules/cars/components/cars-dot-dropdown.vue';
import FleetDayScanBtn from '@/modules/cars/modules/fleet/components/fleet-day-scan-btn.vue';
import CarsSharedService, { CarsSharedServiceS } from '@/modules/cars/cars-shared.service';
import CarsFiltersService, { CarsFiltersServiceS } from '@/modules/cars/cars-filters.service';
import FleetFilterService, { FleetFilterServiceS } from '../fleet-filter.service';

@Component({
    filters: { MIPriceFilter },
    components: {
        'el-table': Table,
        'el-table-column': TableColumn,
        Occupancy,
        CalendarEventsContainer,
        LoaderWrapper,
        CarsDotDropdown,
        FleetDayScanBtn,
    },
})
export default class FleetTable extends Vue {
    @Inject(DocumentFiltersServiceS) private documentFiltersService!: DocumentFiltersService;
    @Inject(UserServiceS) private userService!: UserService;
    @Inject(FleetServiceS) private fleetService!: FleetService;
    @Inject(CarsSharedServiceS) private carsSharedService!: CarsSharedService;
    @Inject(CarsFiltersServiceS) private carsFiltersService!: CarsFiltersService;
    @Inject(FleetFilterServiceS) private fleetFiltersService!: FleetFilterService;
    private tableHeight = '0px';

    mounted() {
        this.$nextTick(() => {
            this.tableHeight = this.height;
        });
        this.fleetFiltersService.initCompetitorFilters();
    }

    get height() {
        const el: HTMLElement | null = document.querySelector('.fleet-table');
        const footer: HTMLElement | null = document.querySelector('footer');

        if (el && footer) {
            const table = el.getBoundingClientRect();
            const footerBox = footer.getBoundingClientRect();
            return `calc(100vh - ${table.top + footerBox.height + 50}px)`;
        }

        return '100vh';
    }

    get brokers(): string[] | null {
        return this.fleetService.companies
            .filter(item => item !== this.userService.currentCompany)
            .filter(item => this.fleetService.competitors.includes(item));
    }

    get myBrokerId() {
        return this.fleetService.currentBrokerId;
    }

    get days(): Day[] {
        return this.documentFiltersService.days;
    }

    menuItems(day: Day) {
        return [
            {
                link: `day-popup/${day}/${this.fleetService.dataSource}`,
                text: 'Details',
            },
        ];
    }

    getCarsCount(day: Day, broker: string): number | null {
        return this.fleetService.getNumberCarByBroker(day, broker);
    }

    getTotal(broker: string): number {
        return this.fleetService.getTotal(broker);
    }

    handleRowClick(day: Day) {
        this.$router.push({
            name: 'fleet-density.table.day-popup-source',
            params: {
                day: String(day),
                source: String(this.fleetService.dataSource),
            },
        });
    }

    handleDotMenuClick(event: Event) {
        event.stopPropagation();
    }

    positionColorClass(broker: string | null, day: Day) {
        if (!broker) {
            return {};
        }
        const company = this.userService.currentCompany;
        if (!company) {
            return {};
        }
        const brokerCount = this.getCarsCount(day, broker);
        const currentBrokerCount = this.getCarsCount(day, company);

        if (!currentBrokerCount || !brokerCount) {
            return {};
        }

        return {
            green: currentBrokerCount > brokerCount,
            red: currentBrokerCount < brokerCount,
        };
    }

    transformDate(day: number): string {
        const { month, year } = this.documentFiltersService.storeState.settings;
        const d = new Date(year, month, day);
        return moment(d).format('MMM, DD/MM/YY');
    }
}
